<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de posts</h3> <small> (Hay {{ totalBlogs }} posts)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">

              <b-button
                v-can-permissions="'Crear categoria post'"
                class="mr-1"
                variant="info"
                @click="$router.push({ name: 'blog-category'})"
              >
                <span class="text-nowrap">Crear categorias</span>
              </b-button>
              <b-button
                variant="primary"
                @click="$router.push({ name: 'blog-create'})"
              >
                <span class="text-nowrap">Crear posts</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refBlogListTable"
        class="position-relative"
        :items="fetchBlogs"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
      >

        <!-- Column: Client -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                variant="light-primary"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.client }}
            </span>
          </b-media>
        </template>

        <!-- Column: Client -->
        <template #cell(photo)="data">
          <b-media vertical-align="center">
            <template #aside>
              <!--              <b-avatar-->
              <!--                size="32"-->
              <!--                :src="data.item.photo"-->
              <!--              />-->
              <b-img
                rounded
                thumbnail
                fluid
                :src="data.item.photo"
                alt="Blog dramox"
              />
            </template>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-send-icon`"
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="$router.push({ name: 'blog-edit', params: { id: data.item.id }})"
            />
            <b-tooltip
              title="Editar"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id}-send-icon`"
            />

            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="deletePost(data.item)"
            />
            <b-tooltip
              title="Eliminar"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-end justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalBlogs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BMedia, BAvatar, BPagination, BTooltip, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import blogHandler from '@/views/dramox/blog/blogHandler'
import blogStoreModule from '@/views/dramox/blog/blogStoreModule'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
    BButton,
    BImg,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-blogs'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, blogStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const {
      // fetchUsers,
      tableColumns,

      perPage,
      currentPage,
      totalBlogs,

      perPageOptions,
      refBlogListTable,

      refetchData,
      fetchBlogs,
    } = blogHandler()

    const deletePost = item => {
      store.dispatch('app-blogs/deleteBlog', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Post eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      // fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalBlogs,
      perPageOptions,
      refBlogListTable,

      refetchData,
      fetchBlogs,
      deletePost,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
