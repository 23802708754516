import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function blogHandler() {
  // Use toast
  const toast = useToast()

  const refBlogListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', label: 'Título', sortable: false },
    { key: 'photo', label: 'Foto', sortable: false },
    { key: 'category', label: 'Categoría', sortable: false },
    { key: 'actions', label: 'Acciones' },
  ]

  const perPage = ref(10)
  const totalBlogs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]

  const refetchData = () => {
    refBlogListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchBlogs = (ctx, callback) => {
    store
      .dispatch('app-blogs/fetchBlogs', {
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, total } = response.data.payload.results

        callback(data)
        totalBlogs.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchBlogs,

    tableColumns,

    perPage,
    currentPage,
    totalBlogs,

    perPageOptions,
    refBlogListTable,

    refetchData,
  }
}
